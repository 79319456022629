<template>
  <div class="container">
    <div class="btns f-c-c">
<!--      <van-button class="confirm-btn" block type="info" @click="handlerDownload">立即下载</van-button>-->
      <van-button class="confirm-btn" block type="info" url="https://a.app.qq.com/o/simple.jsp?pkgname=com.happy.kindergarten">立即下载</van-button>
    </div>

    <div v-if="mask" class="mask">
      <img src="~@/assets/download_guide.png" class="mask-img"/>
    </div>
  </div>
</template>

<script>
const isWeixin = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  if(ua.match(/MicroMessenger/i) == 'micromessenger' || ua.match(/_SQ_/i) == '_sq_'){
      return true;
  } else{
      return false;
  }
}

export default {
  name: 'DownloadPage',

  data: function () {
    return {
      mask: false,
    };
  },

  created() {
  },

  methods: {
    handlerDownload() {
      if (isWeixin()) {
        this.mask = true;
      } else {
        this.download();
      }
    },
    download() {
      var src = 'https://yxl-app.oss-cn-guangzhou.aliyuncs.com/yxl_v1.2_1110_1555.apk';
      var form = document.createElement('form');
      form.action = src;
      document.getElementsByTagName('body')[0].appendChild(form);
      form.submit();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background-image:url('~@/assets/download.jpg');
  background-size: 100% auto;
  .btns {
    position: fixed;
    top: 80vh;
    left: 0;
    right:0;
    margin: auto;
    .confirm-btn {
      width: 5.8rem;
    }
  }
}
.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(152,161,168, 0.6);
  .mask-img {
    position: absolute;
    width: 4rem;
    top: 0;
    right: 0;
  }
}

</style>
